<!-- 通用订单确认弹窗 -->
<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="yf-dialog yf-order-dialog"
    :before-close="handleReset">
    <el-form>
      <h1>请确认订阅信息</h1>
      <ul>
        <li><span class="title">订阅标题：</span>{{ data.title }}</li>
        <li v-show="data.date"><span class="title">服务期限：</span>{{ data.date }}</li>
        <li><span class="title">订阅价格：</span><span class="yf-text_red">{{ data.price }}</span>G币</li>
        <li><span class="title">用户名：</span>{{ $store.state.user.data.userName }}</li>
      </ul>
      <div class="voucher">
        <el-form-item label="使用代金券" prop="voucher">
          <el-select v-model="voucher" placeholder="请选择代金券" @change="setVoucherId" class="left">
            <el-option label="不使用代金券" :value="null"></el-option>
            <el-option
              v-for="item of voucherList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <div class="user">
          <span>需花费<em class="yf-text_red">{{ pay }}</em>G币</span>
          <span class="right">您的G币数：{{ gcoins }} <nuxt-link to="/pay" class="yf-text_red" target="_blank" rel="noopener">充值>></nuxt-link></span>
        </div>
      </div>
      <div class="sup">
        <p class="yf-text_red yf-text_12" v-if="pay > gcoins">
          <i class="iconfont icon-th"></i> 您的G币余额不足</p>
        <el-checkbox v-else v-model="checked">我已经阅读并确认<nuxt-link class="yf-text_red" target="_blank" to="/about?id=18">一富财经免责条款</nuxt-link></el-checkbox>
      </div>
    </el-form>
    <div slot="footer" class="btns">
      <el-button type="primary" :disabled="disabled" @click="handleSubmit">确认订阅</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'dialog-order',
  data () {
    return {
      visible: false,
      data: {
        // 订阅类型<1 vip 2 cjg 4 付费观点>
        type: null,
        // 订阅内容
        title: null,
        // 服务期限
        date: null,
        // 订阅价格
        price: null,
        // 老师id（用于代金券）
        teacher: null
      },
      // 同意
      checked: true,
      // 代金券
      voucherList: [],
      // 当前选中的代金券
      voucher: null
    }
  },
  methods: {
    initData () {
      // 加载优惠券
      let params = {
        page: 1,
        num: 99,
        position: this.data.type,
        use: 1,
        teacher: this.data.teacher
      }
      // 优惠券通用接口
      this.$axios._get('/User/Voucher', params).then(res => {
        this.voucherList = res.list.filter(item => item.minGcoins <= this.data.price)
        this.voucher = this.voucherList.length > 0 ? this.voucherList[0].id : null
      })
    },
    setVoucherId (id) {
      this.voucher = id*1 > 0 ? id : null
    },
    handleReset (done) {
      this.checked = true
      this.voucherList = []
      this.voucher = null
      done()
    },
    handleSubmit () {
      this.close()
      this.$emit('submit', {
        voucher: this.voucher,
        gcoins: this.data.price
      })
    },
    close () {
      this.visible = false
    }
  },
  computed: {
    gcoins () {
      return this.$store.state.user.data.gcoins
    },
    // 计算需要花费的g币数量，和代金券有关
    pay () {
      let gcoins = this.data.price
      if (this.voucherList.length) {
        let current = this.voucherList.find(_ => _.id === this.voucher)

        if (current) {
          gcoins -= current.faceValue
        }
      }
      return gcoins < 0 ? 0 : gcoins
    },
    // 是否可以提交
    disabled () {
      return !this.checked || this.gcoins < this.pay
    }
  }
}
</script>

<style lang="less">
.voucher {
  .el-input--suffix {
    height: 36px;
    .el-input__inner {
      float: left;
    }
  }
}
</style>


<style lang="less" scoped>
form { padding: 0 30px;
  h1 { font-size: 20px; text-align: center; line-height: 60px; }
  ul {
    font-size: 16px; color: #333; line-height: 30px;
    li > span.title {
      width: 80px; text-align: right; display: inline-block;
    }
  }
  .voucher { padding: 15px 55px 8px; background-color: #f5f5f5; margin: 5px 0;
    .user { line-height: 30px; }
  }
  .sup { height: 30px;
    p { line-height: 30px; background-color: #FBFBA5;
      i {font-size: 24px; float: left; padding: 0 5px;}
    }
  }
}
.btns { padding-bottom: 10px; }
</style>
