import Vue from 'vue'
import uuid from 'uuid/v1'
import { createJwt, parseJwt } from '@/utils/Misc'

export const state = () => ({
  guid: null,
  jwt: null,
  adList: {},
  // 未读站内信
  messageNum: 0,
  // 浏览器是否支持webp格式图片
  webp: false
})

export const mutations = {
  setGuid (state, data) {
    state.guid = data
  },
  setJwt (state, data) {
    state.jwt = data
  },
  setWebp (state, flag) {
    state.webp = flag
  },
  setAdData (state, { code, data }) {
    Vue.set(state.adList, code, data)
  },
  setMessage (state, num) {
    state.messageNum = num
  },
  delMessage (state) {
    if (state.messageNum > 0) {
      state.messageNum--
    }
  }
}

export const actions = {
  async nuxtServerInit ({ commit, dispatch }, { $axios }) {
    // 清空所有store
    commit('user/clean')
    let guid = this.$cookies.get('yf_guid') || uuid()
    let jwt = parseJwt(this.$cookies.get('yf_jwt'))
    let isRemember = this.$cookies.get('yf_remember')

    let userId = jwt && jwt.userId > 0 ? jwt.userId : null
    let token = createJwt(guid, userId)

    commit('setGuid', guid)
    commit('setJwt', token)
    this.$cookies.set([
      { name: 'yf_guid', value: guid, expires: 2000 },
      { name: 'yf_jwt', value: token, expires: isRemember ? 2000 : undefined }
    ])
    // 把用户信息保存到store中
    if (userId > 0) {
      // 初始化用户数据
      await dispatch('user/getInfo')
      // 初始化用户关注的老师数据
      await dispatch('user/getLike')
      // 初始化用户免费问答次数/老师未回答问题数量
      await dispatch('user/initAskNum')
      // 获取红包活动金币余额
      await dispatch('user/getRedpackGcoins')
    }
  },

  // 广告位数据统一方法
  getAd ({ commit }, codes) {
    if (typeof codes === 'string') {
      codes = [codes]
    }
    let funs = codes.map(code => {
      return this.$axios._get('/Common/Recommend', { code })
    })

    return Promise.all(funs).then(data => {
      codes.forEach((code, i) => {
        commit('setAdData', { code, data: data[i] })
      })
    }).catch(err => {
      console.log(err)
    })
  },
  // 获取用户站内信
  getMessageNum ({ commit }) {
    this.$axios._get('/Message/Count').then(resp => {
      commit('setMessage', resp.unread)
    }).catch(e => {
      console.log('站内信数据获取失败')
    })
  }
}
