<template>
	<div class="footer_wrap yf-text_12">
		<div class="notice-wrap">
			<section class="main">
				<h2>投资有风险， 入市需谨慎！</h2>
				<p>免责声明：一富财经网站为信息技术平台，平台上的所有信息仅供参考，不作为决策依据；一富财经网站所有博主发布内容仅为个人观点，仅供阅读交流，不构成投资建议，不作为买卖依据，亦不代表一富财经网站的观点或建议，风险自负；一富财经网站不承诺收益，不代客理财，不私下收费，任何私下收费情况属于非法个人行为，与一富财经网站无关，请各位用户务必不要上当受骗！</p>
			</section>
		</div>
		<ul class="foot main">
			<li class="detail">
				<ol class="clearfix">
					<li class="text left">
						<p class="title">关于我们</p>
						<p class="text_link" v-for="item of about" :key="item.id">
							<span @click="link(item.id)">{{ item.name }}</span>
						</p>
					</li>
					<li class="text left">
						<p class="title">使用指南</p>
						<p class="text_link" v-for="item of help" :key="item.id">
							<span @click="link(item.id)">{{ item.name }}</span>
						</p>
					</li>
					<li class="text left">
						<p class="title">安全保障</p>
						<p class="text_link" v-for="item of security" :key="item.id">
							<span @click="link(item.id)">{{ item.name }}</span>
						</p>
					</li>
					<li class="lianxi left">
						<p class="title">联系客服</p>
						<div class="QQ">客服QQ群:<span>850536068</span></div>
						<div class="dianhua">客服电话:<span>18057806199</span><span>17826802022</span></div>
						<div class="time">工作日 8:30-11:30，13:00-17:00</div>
					</li>
					<li class="text left">
						<span class="sprite-footer a" title="一富财经官方微信"></span>
					</li>
				</ol>
			</li>
			<li class="statement">
				一富财经所有刊载文章、数据仅供参考，使用前请务必仔细阅读相关法律法规，风险自负。Copyright © 2011-{{ new Date().getFullYear() }} www.yifucj.com 浙ICP备2024094646号 备案号：<a href="https://beian.miit.gov.cn/" target="_blank">浙ICP备2024094646号-1</a>  地址：浙江省杭州市上城区恒祺商务中心3幢1107
			</li>
			<li class="beian">
				<a href="https://icp.chinaz.com/info?q=yifucj.com&qq-pf-to=pcqq.group" target="_blank" rel="noopener" class="sprite-footer c" title="网站备案中心"></a>
				<a href="//www.315online.com.cn/member/315110096.html" target="_blank" rel="noopener" class="sprite-footer b" title="交易保障中心"></a>
				<p class="beianhao"><span class="sprite-footer d"></span> 浙公网安备 33010402000642号</p>
			</li>
		</ul>
	</div>
</template>

<script>
export default {
	name: 'component-foot',
	data () {
		return {
			about: [],
			help: [],
			security: []
		}
	},
	mounted () {
		this.$axios._get('/Common/Abmenu', { type: 1 }).then(list => {
			list = list.filter(_ => _.status !== 0)
			for (let item of list) {
				switch (item.column) {
					case 1:
						this.about.push(item)
						break
					case 2:
						this.help.push(item)
						break
					case 3:
						this.security.push(item)
						break
				}
			}
		})
	},
	methods: {
		link (id) {
			let query = {}
			if (id === 20) {
				query.type = 2
			} else {
				query.id = id
			}
			this.$router.push({ path: '/about', query })
		}
	}
}
</script>

<style lang="less" scoped>
.footer_wrap { 
	background: #e8e8e8;
	.notice-wrap {
		border-bottom: 1px solid #f6f6f6; padding: 20px 0;
		p {
			font-size: 14px; margin-top: 10px; color: #666666;
		}
	}
	.sprite-footer {
		background-image: url(~assets/sprite-footer.png); display: inline-block;
		&.a { width: 110px; height: 130px; background-position: 0 0; }
		&.b { width: 108px; height: 40px; background-position: 0 -130px; }
		&.c { width: 108px; height: 40px; background-position: 0 -170px; }
		&.d { width: 20px; height: 20px; background-position: 0 -212px; vertical-align: -30%; }
	}
	.foot { padding: 20px 0 1px;
		.detail {
			ol > li { height: 130px;}
			.text { width: 165px; border-right: 1px solid #f6f6f6; text-align: center;
				> img { width: 110px; height: 130px;}
				.title { font-size: 16px; font-weight: 600; color: #615c62; line-height: 26px;}
				.text_link { line-height: 30px;
					> span { color: #818892; cursor: pointer; }
				}
			}
			.text:nth-last-child(1) { border-left: 1px solid #f6f6f6; border-right: none; }
			.lianxi {
				width: 510px; padding-left: 25px; color: #666465; margin-bottom: 27px; float: left; font-size: 16px;
				.title { font-weight: 600; color: #615c62; line-height: 26px; }
				.QQ { height: 26px; line-height: 26px; color: #333;
					span { color: #e23c3e; font-size: 20px; font-weight: 600; margin-left: 8px; }
				}
				.dianhua { color: #333; height: 38px; line-height: 38px;
					span {
						font-size: 18px; font-weight: 600; color: #e6393d;
						&:first-child {
							margin: 0 16px 0 20px;
						}
					}
				}
				.time { color: #333; padding-top: 4px; font-size: 12px; }
			}
		}
		.statement {
			color: #727a7d; text-align: center; margin-bottom: 25px;
		}
		.beian { text-align: center; margin-bottom: 15px;
			a:first-child { margin-right: 20px; }
			.beianhao {
				margin-top: 10px;
			}
		}
	}
}
</style>
