// 自己封装的通用弹出框，包括alert confirm prompt
import Vue from 'vue'
import Login from '~/components/dialog/Login'
import Order from '~/components/dialog/Order'
import Ask from '~/components/dialog/Ask'
import UnlockAsk from '~/components/dialog/UnlockAsk'

const DialogConstructor = Vue.extend()
let instance, loginInstance, orderInstance, askInstance, unlockInstance

const initInstance = () => {
  instance = new DialogConstructor()
}

const iconClassAssoc = {
  'success': 'icon-zq',
  'warning': 'icon-th'
}

const Dialog = function () {

}

Dialog.alert = function (content, opt = {}) {
  if (!instance) {
    initInstance()
  }
  const h = instance.$createElement
  const t = opt.type || 'warning'
  return Vue.prototype.$msgbox({
    message: h('div', null, [
      h('i', { class: `iconfont ${iconClassAssoc[t]}` }, null),
      h('p', null, content)
    ]),
    closeOnClickModal: false,
    closeOnPressEscape: false,
    customClass: 'yf-dialog yf-alert-dialog',
    showCancelButton: opt.showCancelButton === undefined ? false : opt.showCancelButton,
    showConfirmButton: true,
    cancelButtonText: opt.cancelButtonText || '取消',
    confirmButtonText: opt.confirmButtonText || '确定',
    showClose: opt.showClose
  })
}

Dialog.confirm = function (title, content, opt = {}) {
  if (!instance) {
    initInstance()
  }
  const h = instance.$createElement
  return Vue.prototype.$msgbox({
    title: '',
    message: h('div', null, [
      h('h1', null, title),
      h('p', null, content)
    ]),
    customClass: 'yf-dialog yf-confirm-dialog',
    showCancelButton: opt.showCancelButton === undefined ? true : opt.showCancelButton,
    showConfirmButton: true,
    cancelButtonText: opt.cancelButtonText || '取消',
    confirmButtonText: opt.confirmButtonText || '确定'
  })
}

Dialog.prompt = function (message, title, opt) {
  return Vue.prototype.$prompt(message, title, opt)
}

// 弹出登录框
Dialog.login = function () {
  if (process.server) return
  if (!loginInstance) {
    const LoginConstructor = Vue.extend(Login)
    loginInstance = new LoginConstructor({
      el: document.createElement('div'),
      router: Vue.prototype.$nuxt.$router,
      $axios: Vue.prototype.$nuxt.$axios,
      $cookies: Vue.prototype.$nuxt.$cookies
    })
  }
  document.body.appendChild(loginInstance.$el)
  Vue.nextTick(() => {
    loginInstance.visible = true
  })
}

// 订单弹出框
Dialog.order = function (opt, cb) {
  if (process.server) return
  if (!orderInstance) {
    const OrderConstructor = Vue.extend(Order)
    orderInstance = new OrderConstructor({
      el: document.createElement('div'),
      store: Vue.prototype.$nuxt.$store,
      route: Vue.prototype.$nuxt.$route,
      router: Vue.prototype.$nuxt.$router,
      $axios: Vue.prototype.$nuxt.$axios
    })
  }
  document.body.appendChild(orderInstance.$el)
  Vue.nextTick(() => {
    orderInstance.data = opt
    orderInstance.initData()
    orderInstance.$off('submit')
    orderInstance.$on('submit', data => cb(data, orderInstance))
    orderInstance.visible = true
  })
}

// 付费问免费问
Dialog.ask = function (option) {
  if (process.server) return
  if (!askInstance) {
    const AskConstructor = Vue.extend(Ask)
    askInstance = new AskConstructor({
      el: document.createElement('div'),
      store: Vue.prototype.$nuxt.$store,
      route: Vue.prototype.$nuxt.$route,
      router: Vue.prototype.$nuxt.$router,
      $axios: Vue.prototype.$nuxt.$axios
    })
  }
  document.body.appendChild(askInstance.$el)
  Vue.nextTick(() => {
    askInstance.visible = true
    askInstance.initData(option)
  })
}

// 解锁问答
Dialog.unlockAsk = function (data, callback) {
  if (process.server) return
  if (!unlockInstance) {
    const UnlockConstructor = Vue.extend(UnlockAsk)
    unlockInstance = new UnlockConstructor({
      el: document.createElement('div'),
      store: Vue.prototype.$nuxt.$store,
      route: Vue.prototype.$nuxt.$route,
      router: Vue.prototype.$nuxt.$router,
      $axios: Vue.prototype.$nuxt.$axios
    })
  }
  document.body.appendChild(unlockInstance.$el)
  Vue.nextTick(() => {
    unlockInstance.initData(data)
    unlockInstance.$off('update')
    unlockInstance.$on('update', data => callback(data))
    unlockInstance.visible = true
  })
}

export default Dialog
