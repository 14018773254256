import { render, staticRenderFns } from "./error.vue?vue&type=template&id=3d73e020&scoped=true&"
import script from "./error.vue?vue&type=script&lang=js&"
export * from "./error.vue?vue&type=script&lang=js&"
import style0 from "./error.vue?vue&type=style&index=0&id=3d73e020&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3d73e020",
  null
  
)

component.options.__file = "error.vue"
export default component.exports