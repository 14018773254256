<template>
  <el-dialog
    :visible.sync="visible"
    custom-class="yf-dialog yf-login-dialog"
    :before-close="handleReset">
    <el-form class="login_body" :model="form" label-width="60px">
      <p class="login_logo">
        <span>登录</span><img src="~assets/vipindex1_logo.jpg" alt="投资者教育平台" />
      </p>
      <el-form-item label="用户名">
        <el-input v-model.trim="form.username" placeholder="请输入手机号/邮箱/昵称" @keydown.enter.native="$refs.password.focus()"></el-input>
      </el-form-item>
      <el-form-item label="密　码">
        <el-input ref="password" type="password" v-model="form.password" @keydown.enter.native="handleLogin" placeholder="请输入密码"></el-input>
      </el-form-item>
      <div class="sup">
        <el-checkbox label="自动登录" v-model="form.remember"></el-checkbox>
        <span class="right" @click="push('/forgot')">忘记密码</span>
      </div>
    </el-form>
    <div slot="footer" class="btns">
      <a class="el-button el-button--default" @click="push('/register')" href="javascript:;">没有账号？立即注册</a>
      <el-button type="primary" @click="handleLogin">登录</el-button> 
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'dialog-login',
  data() {
    return {
      visible: false,
      form: {
        username: null,
        password: null,
        remember: true,
      }
    }
  },
  methods: {
    push (path) {
      this.visible = false
      this.$router.push(path)
    },
    handleLogin () {
      this.$axios._post('/Auth/Login', {
        username: this.form.username,
        password: this.form.password,
        type: 0
      }).then(res => {
          // 设置token
          let remember = this.form.remember
          this.$cookies.set('yf_jwt', res.token, remember ? 2000 : undefined)
          remember ? this.$cookies.set('yf_remember', 1, 2000) : this.$cookies.remove('yf_remember')
          // 刷新页面
          window.location.reload()
        }).catch(error => {
          console.log(error)
        })
    },
    // 重置表单
    handleReset (done) {
      this.form = {
        username: null,
        password: null,
        remember: true,
      }
      done()
    }
  }
}
</script>

<style lang="less" scoped>
form { padding: 0 50px;
  .login_logo { padding-bottom: 30px;
    span { font-size: 31px; display: inline-block; height: 30px; line-height: 30px; color: #9a9a9a; vertical-align: middle;}
    img { height: 30px; vertical-align: middle;}
  }
  .sup { padding-bottom: 20px;
    span { color: #5097ed; line-height: 41px; cursor: pointer; }
  }
}
.btns { padding-bottom: 30px; margin-top: -40px; }
</style>
