// 老师相关数据
export const state = () => ({
  // 老师数据
  data: {},
  // 老师正在销售的文章
  saleArticle: [],
  // 老师正在销售的特训班
  saleVip: null,
  // 禁言列表
  forbidList: [],
  // 老师得票数
  totalVoteNum: 0
})

export const getters = {
  // 当前用户是否是当前查阅的老师
  isTeacher (state, getters, rootState) {
    return rootState.user.data && state.data && rootState.user.data.id === state.data.id
  },

  // 当前老师正在销售的产品（付费文章+特训班）
  goodsData (state) {
    let data = []
    if (state.saleArticle.length > 0) {
      for (let item of state.saleArticle) {
        data.push({ type: 'article', data: item })
      }
    }
    if (state.saleVip) {
      data.push({ type: 'vip', data: state.saleVip })
    }
    return data
  }
}

export const actions = {
  // 获取老师基本数据
  getInfo ({ commit }, id) {
    return this.$axios._get('/Teacher/Info', { id }).then(res => {
      commit('setData', res)
      return res
    }).catch(err => {
      console.log(err)
      return err
    })
  },
  // 获取师可售卖产品
  async getGoods ({ commit }, id) {
    try {
      const data = await this.$axios._get('/Teacher/Goods', { id })
      // console.log(data)
      commit('setSaleArticle', data.article)
      commit('setSaleVip', data.training)
      return data
    } catch (e) {
      console.log(e, '获取师可售卖产品[/Teacher/Goods]')
      return Promise.reject(e)
    }
  }
}

export const mutations = {
  setData (state, data) {
    state.data = data
  },
  setDetailData (state, data) {
    state.detailData = data
  },
  setSaleArticle (state, data) {
    state.saleArticle = data
  },
  setSaleVip (state, data) {
    state.saleVip = data
  },
  setAskPrice (state, data) {
    state.data.questionPrice = data
  },
  initVoteNum (state) {
    state.totalVoteNum = 0
  },
  setVoteNum (state, num) {
    state.totalVoteNum += num
  },
  setEncryptedViewMonth (state, data) {
    state.data.encryptedViewMonth = data
  },
  // 初始化直播室禁言用户列表
  initForbidList (state, list) {
    state.forbidList = list
  },
  // 添加禁言用户
  addForbidUser (state, user) {
    state.forbidList.push(user)
  },
  // 移除禁言用户
  removeForbidUser (state, Ids) {
    for (let i = 0; i < Ids.length; i++) {
      let index = state.forbidList.map(_ => _.id).indexOf(Ids[i])
      if (index !== -1) {
        state.forbidList.splice(index, 1)
      }
    }
  },
  clean (state) {
    state.data = null
  }
}
