<template>
<div class="top-nav-container" :style="{ marginTop: isIE ? '40px' : 0 }">
  <div class="main-1 clearfix">
    <div class="logo left">
      <img src="~assets/logo.jpg" alt="logo" />
    </div>
    <div class="page-nav left">
      <nuxt-link v-for="item of navList" :key="item.path" :to="item.path" :exact="item.path === '/'">{{ item.title }}</nuxt-link>
    </div>
    <div class="search right">
      <input v-model.trim="keyword" @keydown.enter="search" placeholder="输入老师名..." /><i @click="search" class="iconfont icon-ss center"></i>
    </div>
  </div>
</div>
</template>

<script>
export default {
  data () {
    return {
      navList: [
        { title: '首页', path: '/' },
        { title: '互动', path: '/room' },
        { title: '问答', path: '/question' },
        { title: '文章', path: '/article' },
        // { title: '快讯', path: '/news' },
        // { title: '特训班', path: '/vip' },
        { title: '图书馆', path: '/library' },
        { title: '我的', path: '/user' },
        { title: '关于', path: '/about' },
      ],
      keyword: '',
      unread: '',
      isIE: false
    }
  },
  mounted () {
    // position: sticky 不适用IE
    this.isIE = /Trident/.test(navigator.userAgent)

    // this.$axios.get('/Common/IpCity').then(res => {
    //     if(res==='杭州'){
    //       this.navList = [
    //           // { title: '首页', path: '/' },
    //           // { title: '问答', path: '/question' },
    //           // { title: '文章', path: '/article' },
    //           { title: '快讯', path: '/news' },
    //           // { title: '图书馆', path: '/library' },
    //           // { title: '我的', path: '/user' },
    //           // { title: '关于', path: '/about' },
    //         ]
    //     }
    //   })
  },
  methods: {
    search () {
      if (this.keyword !== '') {
        this.$router.push({ path: '/search', query: { keyword: this.keyword } })
        this.keyword = ''
      }
    }
  }
}
</script>

<style lang="less">
.top-nav-container {
  background: #e43a3d;
  .logo {
    img {
      width: 120px; height: 43px; display: inline-block; margin: 24px 100px 0 0;
    }
  }
  .page-nav {
    overflow: hidden;
    a {
      float: left; line-height: 90px; width: 80px; font-size: 16px; color: #fff; text-align: center;
      &:hover:not(.nuxt-link-active) { background: rgba(198, 20, 32, .4); }
      &.nuxt-link-active { background: #C61420; font-weight: bold; }
    }
  }
  .search {
    overflow: hidden; background: #FFFFFF; border-radius: 4px; margin-top: 25px;
    input {
      float: left; outline: none; height: 40px; width: 200px; border: none; text-indent: 10px;
    }
    i {
      float: left; width: 40px; height: 40px; cursor: pointer; font-size: 22px; padding-top: 9px; color: #999;
    }
  }
}
</style>
