<template>
  <div>
    <div v-if="error.statusCode === 601" class="error-page-601">
      <div class="content">
        <p><img src="~assets/low_explorer.jpg" alt="网站不支持IE 9以下的浏览器，敬请谅解！"></p>
        <p class="text">为了获得最佳体验，我们推荐您使用以下浏览器，请点击图标转到相应下载页</p>
        <p>
          <a target="_blank" rel="noopener" href="https://www.google.cn/chrome/index.html">
            <img src="~static/img/chrome.jpg" alt="谷歌浏览器">
          </a>
          <a target="_blank" rel="noopener" href="https://www.firefox.com.cn/">
            <img src="~static/img/firefox.jpg" alt="火狐浏览器">
          </a>
        </p>
      </div>
    </div>
    <div v-if="error.statusCode === 404" class="error-page-404">
      <div>
        <h1 class="error-code">{{ error.statusCode }}</h1>
        <div class="error-wrapper-message">
          <h2 class="error-message">{{ error.message }}</h2>
        </div>
        <p><nuxt-link class="error-link" to="/">前往一富财经首页</nuxt-link></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'nuxt-error',
  props: ['error'],
  head () {
    return {
      title: this.error.message || 'An error occured'
    }
  }
}
</script>

<style lang="less" scoped>
.error-page-601 {
  position: fixed; left: 0; right: 0; top: 0; bottom: 0; background: #fff; z-index: 2010;
  .content {
    width: 630px; position: absolute; left: 50%; top: 15%; margin-left: -315px;
    p {
      text-align: center;
      &.text {
        line-height: 60px;
      }
      a {
        display: inline-block; margin: 10px 20px;
      }
    }
  }
}
.error-page-404 {
  color: #000; background: #fff; top: 0; bottom: 0; left: 0; right: 0; position: absolute; font-family: "SF UI Text", "Helvetica Neue", "Lucida Grande"; text-align: center; padding-top: 20%;
  .error-code {
    display: inline-block; font-size: 24px; font-weight: 500; vertical-align: top; border-right: 1px solid rgba(0, 0, 0, 0.298039); margin: 0px 20px 0px 0px; padding: 10px 23px;
  }
  .error-wrapper-message {
    display: inline-block; text-align: left; line-height: 51px; height: 51px; vertical-align: middle;
    .error-message {
      font-size: 14px;
      font-weight: normal;
      margin: 0px;
      padding: 0px;
    }
  }
  p {
    margin-top: 20px;
    .error-link {
      color: #67C23A; font-weight: normal; text-decoration: none; font-size: 14px;
      &:after {
        content: ' \00bb';
      }
      &:hover {
        text-decoration: underline;
      }
    }
  }

}

</style>
