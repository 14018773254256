import Vue from 'vue'
import {
  Pagination,
  Dialog,
  Button,
  Upload,
  DatePicker,
  TimeSelect,
  Checkbox,
  Select,
  Option,
  Input,
  Form,
  FormItem,
  Tag,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  Popover,
  CheckboxGroup,
  Tooltip,
  Radio,
  RadioGroup,
  Icon,
  Notification,
  Row,
  Col,
  MessageBox
} from 'element-ui'

const Globle = function (Vue) {
  Vue.prototype.$notify = Notification
  Vue.prototype.$msgbox = MessageBox
  Vue.prototype.$prompt = MessageBox.prompt
  Vue.prototype.$confirm = MessageBox.confirm
}

const plugins = [
  Globle,
  Pagination,
  Dialog,
  Button,
  Upload,
  DatePicker,
  TimeSelect,
  Checkbox,
  Select,
  Option,
  Input,
  Form,
  FormItem,
  Tag,
  Tabs,
  TabPane,
  Table,
  TableColumn,
  Carousel,
  CarouselItem,
  Popover,
  CheckboxGroup,
  Tooltip,
  Radio,
  RadioGroup,
  Icon,
  Row,
  Col
]

plugins.forEach(plugin => {
  Vue.use(plugin)
})
