import { isEltIE10 } from '~/utils/Misc'

export default function ({ req, error }) {
  // 低版本提示, 服务端和客户端获取agent不同
  const agent = process.server ? req.headers['user-agent'] : navigator.userAgent

  if (isEltIE10(agent)) {
    error({
      statusCode: 601, // 自定义的错误代码
      message: '您所访问的网站不支持 IE10以下浏览器（包括IE10）'
    })
  }
}
