import jwt from 'jsonwebtoken'

export const isEltIE10 = function (userAgent) {
  return /MSIE\s10|MSIE\s9|MSIE\s8|MSIE\s7|MSIE\s6/.test(userAgent)
}

export const playAudio = function (play) {
  if (play) {
    var audio = new Audio('/tone.mp3')
    audio.play()
  }
}

export const createJwt = function (guid, id = null) {
  if (!guid) {
    console.warn('error guid')
    return false
  }
  const payload = { from: 'pc' }
  if (id > 0) payload.userId = id
  return jwt.sign(payload, Buffer.from(process.env.JWT_SECRET, 'base64'), { expiresIn: '1d', jwtid: guid })
}

export const parseJwt = function (token) {
  if (!token) return false
  try {
    return jwt.decode(token)
  } catch (err) {
    throw err
  }
}
