import { render, staticRenderFns } from "./Order.vue?vue&type=template&id=baa1f840&scoped=true&"
import script from "./Order.vue?vue&type=script&lang=js&"
export * from "./Order.vue?vue&type=script&lang=js&"
import style0 from "./Order.vue?vue&type=style&index=0&lang=less&"
import style1 from "./Order.vue?vue&type=style&index=1&id=baa1f840&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "baa1f840",
  null
  
)

component.options.__file = "Order.vue"
export default component.exports