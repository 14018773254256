import Dialog from '~/utils/Dialog'

export default function ({ route, store, redirect, from }) {
  /*
   *  下面的代码请参考路由导航卫士，route相当于to，from只有在非服务端渲染时才有数据
   */
  if (!store.state.user.data) {
    // 首次渲染和页面刷新属于服务端渲染
    if (process.server) {
      // /user相关页面下退出登录时跳转到首页
      if (/^\/user/.test(route.fullPath) || /^\/room\/apply/.test(route.fullPath) || /^\/pay/.test(route.fullPath)) {
        redirect('/')
      }
    } else {
      // 非/user页面跳转到/user相关页面时
      if (/^\/user/.test(route.fullPath)) {
        // 用fullPath是考虑到用户当前页面带有query时
        redirect(from.fullPath)
        Dialog.login()
      }
    }
  } else if (/^\/login/.test(route.fullPath) || /^\/register/.test(route.fullPath) || /^\/forgot/.test(route.fullPath) || (/^\/room\/apply$/.test(route.fullPath) && store.getters['user/isTeacher'])) {
    // 用户登录情况下
    // 禁止直接输入地址进入登录、注册、找回密码页面
    // 如果用户身份是老师，则不能再通过地址栏进入申请老师页面
    redirect('/')
  }
}
