import Vue from 'vue'
import VueQuillEditor from 'vue-quill-editor/dist/ssr'

const Quill = VueQuillEditor.Quill
const Size = Quill.import('attributors/style/size')
Size.whitelist = ['14px', '16px', '18px', '20px', '26px']
Quill.register('formats/size', Size, true)
Quill.register('formats/swf', require('~/utils/editor/Swf').default)
Quill.register('modules/imageImport', require('~/utils/editor/ImageImport').default)

Vue.use(VueQuillEditor)
