<template>
  <!-- 2017年终评选活动布局 -->
  <div class="activity-2018-wrap">
    <YTop/>
    <header></header>
    <nuxt/>
    
    <!--内容 over-->
    <!-- <footer>
      <div id="line-4" class="main-1 activity-padding">
        <h1 title="充值送G币" class="h1-sprite h1-sprite-4"></h1>
        <p class="activity-text">即日起至1月26日15：00，通过<span class="color-y">以下应急账户充值</span>，单笔充值100G币以上<span class="color-y">赠送3%G币</span>（赠送的G币取整数）</p>
        <ul class="clearfix activity-bg-box">
          <li class="left h1-sprite zhifu-sprite-0"></li>
          <li class="left h1-sprite zhifu-sprite-1"></li>
          <li class="left h1-sprite zhifu-sprite-2"></li>
        </ul>
        <ol class="activity-ol">
          <h1 class="color-y">充值流程与说明</h1>
          <li>
            <span class="h1-sprite h1-sprite-5"><i>1</i></span>选择以上账户进行转账，为方便核对，转账金额最好选择501，503等
          </li>
          <li>
            <span class="h1-sprite h1-sprite-5"><i>2</i></span>转账完成后，请联系客服，告知您的汇款金额和用户名，工作人员手动帮您充值。客服QQ ：<b>1536972572</b>客服电话：<b>0571-87119797</b>
          </li>
          <li>
            <span class="h1-sprite h1-sprite-5"><i>3</i></span>客服核对信息无误后完成充值，赠送G币将于当日（工作日）下午5点前到账
          </li>
          <li>
            <span class="h1-sprite h1-sprite-5"><i>4</i></span>凡通过应急账户汇款的，表示您已阅读并同意一富财经G币购买使用协议。
          </li>
          <li>
            <span class="h1-sprite h1-sprite-5"><i>5</i></span>特别说明：<span class="color-y">本次充值活动仅限以上3个应急账户，通过其他方式充值的不予赠送</span>
          </li>
        </ol>
      </div>
      <p style="background-color: #201c19;">本活动最终解释权归一富财经所有</p>
    </footer> -->
    <footer>本活动最终解释权归一富财经所有</footer>
  </div>
</template>

<script>
import YTop from '@/components/Top'

export default {
  name: 'activity',
  components: {
    YTop
  }
}
</script>

<style lang="less" scoped>
.activity-2018-wrap {
  background: #A81E1C;
  header {
    width: 100%; height: 850px; background: url('~assets/activity/top-bg-min.jpg') no-repeat top center;
  }
  footer {
    background-color: #201c19; text-align: center; font-size: 16px; line-height: 50px; color: #a9a8a8;
    // #line-4 {
    //   ul {
    //     padding: 30px 36px; margin-top: 30px;
    //     li {
    //       margin: 0 12px; width: 350px; height: 200px;
    //     }
    //   }
    // }
  }
}
</style>
