import Vue from 'vue'
import Qs from 'qs'
import jsonp from 'jsonp'

export default ({ $axios, app }) => {
  $axios.defaults.baseURL = process.env.API_URL
  $axios.defaults.timeout = 5000

  $axios.onRequest(config => {
    const guid = Vue.prototype.$nuxt.$store.state.guid
    const jwt = Vue.prototype.$nuxt.$store.state.jwt
    if (guid) config.headers.GUID = guid
    if (jwt) config.headers.Authorization = jwt
    return config
  })

  $axios.onResponse(response => {
    if (response.data.error > 0) {
      // 浏览器状态下，直接显示错误内容
      if (process.client) {
        Vue.prototype.$notify({
          type: 'error',
          title: '服务器请求失败',
          message: response.data.message,
          duration: 5000
        })
      }
      return Promise.reject(response.data)
    }
    return response.data.data
  })

  $axios.onError(error => {
    console.warn(error)
  })

  $axios._get = (path, params) => {
    return $axios.get(path, { params })
  }

  $axios._post = (path, params) => {
    return $axios.post(path, Qs.stringify(params))
  }

  $axios.postFile = (path, params, file) => {
    let formData = new FormData()
    formData.append('file', file)
    for (let i in params) {
      formData.append(i, params[i])
    }
    return $axios.post(path, formData)
  }

  $axios.getJsonp = (url, data = null, cbstr = null) => {
    return new Promise((resolve, reject) => {
      if (data) {
        url = url + '?' + Qs.stringify(data)
      }
      const param = cbstr ? { param: cbstr } : null
      jsonp(url, param, (err, data) => {
        if (err) {
          return reject(err.message)
        } else {
          return resolve(data)
        }
      })
    })
  }
}
