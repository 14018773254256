<template>
  <div id="app">
    <Top />
    <TopNav />

    <div class="content-wrap">
      <nuxt />
    </div>
    
    <Footer />
    <!-- 全局警告弹窗 -->
    <el-dialog
      title="温馨提示"
      :visible.sync="globalNotice"
      :show-close="false"
      :close-on-click-modal="false"
      center>
      <p class="warn-content">1、一富财经网站相关资讯、行情、数据、信息均为摘录；一富财经网站博主所发表的内容仅为个人观点，仅供阅读交流，不构成投资建议，不作为买卖依据，据此操作风险自负；</p>
      <p class="warn-content">2、 一富财经网站不承诺收益、不带客理财，不私下收费、任何私下收费情况都属于违规行为，请及时与平台联系；</p>
      <p class="warn-content">3、请认真查看互动内容，并根据自身经济实力、投资经验和风险承受能力独立思考做出决策，承担风险和可能的损失，而不应当以任何信息取代独立判断。</p>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" @click="onWarn">我知道了</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Top from '@/components/Top'
import TopNav from '@/components/TopNav'
import Footer from '@/components/Footer'
import moment from 'moment'
import { setInterval, clearInterval } from 'timers';

export default {
  name: 'app',
  data () {
    return {
      globalNotice: false,
      timer: null,
      t: 0
    }
  },
  mounted () {
    // 首次进入页面发送百度统计代码分析
    _hmt.push(['_trackPageview', this.$route.path])
    // 网站通知
    let isNotice = this.$cookies.get('yf_notice')
    if (isNotice !== moment().format('YYYY-MM-DD')) {
      this.globalNotice = true
    }
    // 判断浏览器是否支持webp格式图片，详情看mixins/webp
    let isSupportWebp = document.createElement('canvas').toDataURL('image/webp').indexOf('data:image/webp') === 0
    this.$store.commit('setWebp', isSupportWebp)
    // 用户成长
    if (this.$isLogin()) {
      this.handleGrow()
    }
  },
  methods: {
    onWarn () {
      this.$cookies.set('yf_notice', moment().format('YYYY-MM-DD'), 1)
      this.globalNotice = false
    },
    handleGrow () {
      // 在线超过十分钟获得成长值
      if (this.$cookies.get('yf_grow') !== moment().format('YYYY-MM-DD')) {
        this.timer = setInterval(() => {
          this.t++
          if (this.t === 600) {
            this.$axios._post('/GrowthRecord/Line').then(() => {
              this.$cookies.set('yf_grow', moment().format('YYYY-MM-DD'), 1)
              this.t = 0
              clearInterval(this.timer)
              this.timer = null
            })
          }
        }, 1000)
      }
    }
  },
  beforeDestroy () {
    if (this.timer) {
      clearInterval(this.timer)
      this.timer = null
    }
  },
  components: {
    Top,
    TopNav,
    Footer
  }
}
</script>

<style lang="less">
#app {
  min-width: 1200px;
  .warn-content {
    font-size: 16px; padding: 0 20px;
    &:not(:first-child) {
      margin-top: 20px;
    }
  }
  .content-wrap { background: #fff; }
}
</style>

