import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'

const _7e8cb150 = () => interopDefault(import('..\\pages\\about.vue' /* webpackChunkName: "pages_about" */))
const _737b806e = () => interopDefault(import('..\\pages\\activity\\index.vue' /* webpackChunkName: "pages_activity_index" */))
const _0db73de1 = () => interopDefault(import('..\\pages\\article\\index.vue' /* webpackChunkName: "pages_article_index" */))
const _052c90ab = () => interopDefault(import('..\\pages\\baidu_verify_dl6gj1sBLN.html.vue' /* webpackChunkName: "pages_baidu_verify_dl6gj1sBLN.html" */))
const _1cbc3a30 = () => interopDefault(import('..\\pages\\forgot.vue' /* webpackChunkName: "pages_forgot" */))
const _63afc8fc = () => interopDefault(import('..\\pages\\library\\index.vue' /* webpackChunkName: "pages_library_index" */))
const _abcdb598 = () => interopDefault(import('..\\pages\\login.vue' /* webpackChunkName: "pages_login" */))
const _0a8e7fb3 = () => interopDefault(import('..\\pages\\pay.vue' /* webpackChunkName: "pages_pay" */))
const _42817728 = () => interopDefault(import('..\\pages\\register.vue' /* webpackChunkName: "pages_register" */))
const _33518ca2 = () => interopDefault(import('..\\pages\\room\\index.vue' /* webpackChunkName: "pages_room_index" */))
const _4d04ca26 = () => interopDefault(import('..\\pages\\search.vue' /* webpackChunkName: "pages_search" */))
const _c496289c = () => interopDefault(import('..\\pages\\user\\index.vue' /* webpackChunkName: "pages_user_index" */))
const _40e03c90 = () => interopDefault(import('..\\pages\\user\\index\\index.vue' /* webpackChunkName: "pages_user_index_index" */))
const _623794ce = () => interopDefault(import('..\\pages\\user\\index\\article\\index.vue' /* webpackChunkName: "pages_user_index_article_index" */))
const _47897ae4 = () => interopDefault(import('..\\pages\\user\\index\\fcoins.vue' /* webpackChunkName: "pages_user_index_fcoins" */))
const _5ef698a6 = () => interopDefault(import('..\\pages\\user\\index\\gcoins.vue' /* webpackChunkName: "pages_user_index_gcoins" */))
const _122c564b = () => interopDefault(import('..\\pages\\user\\index\\home-message.vue' /* webpackChunkName: "pages_user_index_home-message" */))
const _2526bdf2 = () => interopDefault(import('..\\pages\\user\\index\\honer.vue' /* webpackChunkName: "pages_user_index_honer" */))
const _6ffaf884 = () => interopDefault(import('..\\pages\\user\\index\\inbox\\index.vue' /* webpackChunkName: "pages_user_index_inbox_index" */))
const _2fa6dff6 = () => interopDefault(import('..\\pages\\user\\index\\income-statistics.vue' /* webpackChunkName: "pages_user_index_income-statistics" */))
const _0eaf408a = () => interopDefault(import('..\\pages\\user\\index\\invite.vue' /* webpackChunkName: "pages_user_index_invite" */))
const _3be32000 = () => interopDefault(import('..\\pages\\user\\index\\level\\index.vue' /* webpackChunkName: "pages_user_index_level_index" */))
const _152b9fb8 = () => interopDefault(import('..\\pages\\user\\index\\live\\index.vue' /* webpackChunkName: "pages_user_index_live_index" */))
const _059def33 = () => interopDefault(import('..\\pages\\user\\index\\outbox\\index.vue' /* webpackChunkName: "pages_user_index_outbox_index" */))
const _19f6bacc = () => interopDefault(import('..\\pages\\user\\index\\pay-statistics.vue' /* webpackChunkName: "pages_user_index_pay-statistics" */))
const _7e76bc2c = () => interopDefault(import('..\\pages\\user\\index\\question\\index.vue' /* webpackChunkName: "pages_user_index_question_index" */))
const _fc9bf958 = () => interopDefault(import('..\\pages\\user\\index\\teacher-like.vue' /* webpackChunkName: "pages_user_index_teacher-like" */))
const _215fcbce = () => interopDefault(import('..\\pages\\user\\index\\viewpoint\\index.vue' /* webpackChunkName: "pages_user_index_viewpoint_index" */))
const _4bb84847 = () => interopDefault(import('..\\pages\\user\\index\\vip\\index.vue' /* webpackChunkName: "pages_user_index_vip_index" */))
const _259162ec = () => interopDefault(import('..\\pages\\user\\index\\voucher.vue' /* webpackChunkName: "pages_user_index_voucher" */))
const _68f70aa1 = () => interopDefault(import('..\\pages\\user\\index\\article\\deliver.vue' /* webpackChunkName: "pages_user_index_article_deliver" */))
const _f0c96938 = () => interopDefault(import('..\\pages\\user\\index\\article\\order-record.vue' /* webpackChunkName: "pages_user_index_article_order-record" */))
const _42664393 = () => interopDefault(import('..\\pages\\user\\index\\level\\record.vue' /* webpackChunkName: "pages_user_index_level_record" */))
const _af1926ca = () => interopDefault(import('..\\pages\\user\\index\\live\\as-guest.vue' /* webpackChunkName: "pages_user_index_live_as-guest" */))
const _4f547cc4 = () => interopDefault(import('..\\pages\\user\\index\\question\\deblock.vue' /* webpackChunkName: "pages_user_index_question_deblock" */))
const _fe7fc866 = () => interopDefault(import('..\\pages\\user\\index\\viewpoint\\package.vue' /* webpackChunkName: "pages_user_index_viewpoint_package" */))
const _073fd111 = () => interopDefault(import('..\\pages\\user\\index\\viewpoint\\single.vue' /* webpackChunkName: "pages_user_index_viewpoint_single" */))
const _5cd32ce1 = () => interopDefault(import('..\\pages\\user\\index\\vip\\orderlist.vue' /* webpackChunkName: "pages_user_index_vip_orderlist" */))
const _058b44b4 = () => interopDefault(import('..\\pages\\user\\index\\inbox\\_id.vue' /* webpackChunkName: "pages_user_index_inbox__id" */))
const _db97bb4a = () => interopDefault(import('..\\pages\\user\\index\\outbox\\_id.vue' /* webpackChunkName: "pages_user_index_outbox__id" */))
const _6dc572da = () => interopDefault(import('..\\pages\\vip\\index.vue' /* webpackChunkName: "pages_vip_index" */))
const _6016647a = () => interopDefault(import('..\\pages\\article\\list\\index.vue' /* webpackChunkName: "pages_article_list_index" */))
const _6e80d054 = () => interopDefault(import('..\\pages\\question\\teacher-list.vue' /* webpackChunkName: "pages_question_teacher-list" */))
const _30898d84 = () => interopDefault(import('..\\pages\\room\\apply.vue' /* webpackChunkName: "pages_room_apply" */))
const _11efed32 = () => interopDefault(import('..\\pages\\vip\\edit.vue' /* webpackChunkName: "pages_vip_edit" */))
const _5bd45650 = () => interopDefault(import('..\\pages\\article\\detail\\_id.vue' /* webpackChunkName: "pages_article_detail__id" */))
const _0ed164eb = () => interopDefault(import('..\\pages\\article\\list\\_id.vue' /* webpackChunkName: "pages_article_list__id" */))
const _b2d24ca4 = () => interopDefault(import('..\\pages\\question\\detail\\_id.vue' /* webpackChunkName: "pages_question_detail__id" */))
const _2459a741 = () => interopDefault(import('..\\pages\\question\\list\\_id.vue' /* webpackChunkName: "pages_question_list__id" */))
const _2c3575b3 = () => interopDefault(import('..\\pages\\room\\history-list\\_id.vue' /* webpackChunkName: "pages_room_history-list__id" */))
const _7060e486 = () => interopDefault(import('..\\pages\\room\\history\\_id.vue' /* webpackChunkName: "pages_room_history__id" */))
const _b0c6a9de = () => interopDefault(import('..\\pages\\vip\\detail\\_id.vue' /* webpackChunkName: "pages_vip_detail__id" */))
const _660199c7 = () => interopDefault(import('..\\pages\\vip\\room\\_id.vue' /* webpackChunkName: "pages_vip_room__id" */))
const _5c4371da = () => interopDefault(import('..\\pages\\intro\\_id.vue' /* webpackChunkName: "pages_intro__id" */))
const _97c2d160 = () => interopDefault(import('..\\pages\\library\\_book\\index.vue' /* webpackChunkName: "pages_library__book_index" */))
const _16718340 = () => interopDefault(import('..\\pages\\question\\_t.vue' /* webpackChunkName: "pages_question__t" */))
const _79be4f8a = () => interopDefault(import('..\\pages\\room\\_id.vue' /* webpackChunkName: "pages_room__id" */))
const _c83d9000 = () => interopDefault(import('..\\pages\\library\\_book\\_chapter.vue' /* webpackChunkName: "pages_library__book__chapter" */))
const _3f1cfbc6 = () => interopDefault(import('..\\pages\\index.vue' /* webpackChunkName: "pages_index" */))

Vue.use(Router)

const scrollBehavior = (to, from, saved) => {
      if (saved) {
        return saved
      } else if (to.path === from.path) {
        // 点击分页时
        let y
        switch (to.name) {
          case 'about':
            y = 0
            break
          case 'article-list':
            y = 110
            break
          case 'news':
            y = 100
            break
          case 'question-teacher-list':
            y = 120
            break
          case 'article-list-id':
          case 'question-list-id':
          case 'room-history-list-id':
            y = 285
            break
          case 'question-t':
          case 'vip':
            y = 403
            break
        }
        return { x: 0, y }
      } else if (to.matched.some((r) => r.components.default.options.scrollToTop)) {
        return { x: 0, y: 0 }
      } else {
        return { x: 0, y: 0 }
      }
    }

export function createRouter() {
  return new Router({
    mode: 'history',
    base: '/',
    linkActiveClass: 'nuxt-link-active',
    linkExactActiveClass: 'nuxt-link-exact-active',
    scrollBehavior,

    routes: [{
      path: "/about",
      component: _7e8cb150,
      name: "about"
    }, {
      path: "/activity",
      component: _737b806e,
      name: "activity"
    }, {
      path: "/article",
      component: _0db73de1,
      name: "article"
    }, {
      path: "/baidu_verify_dl6gj1sBLN.html",
      component: _052c90ab,
      name: "baidu_verify_dl6gj1sBLN.html"
    }, {
      path: "/forgot",
      component: _1cbc3a30,
      name: "forgot"
    }, {
      path: "/library",
      component: _63afc8fc,
      name: "library"
    }, {
      path: "/login",
      component: _abcdb598,
      name: "login"
    }, {
      path: "/pay",
      component: _0a8e7fb3,
      name: "pay"
    }, {
      path: "/register",
      component: _42817728,
      name: "register"
    }, {
      path: "/room",
      component: _33518ca2,
      name: "room"
    }, {
      path: "/search",
      component: _4d04ca26,
      name: "search"
    }, {
      path: "/user",
      component: _c496289c,
      children: [{
        path: "",
        component: _40e03c90,
        name: "user-index"
      }, {
        path: "article",
        component: _623794ce,
        name: "user-index-article"
      }, {
        path: "fcoins",
        component: _47897ae4,
        name: "user-index-fcoins"
      }, {
        path: "gcoins",
        component: _5ef698a6,
        name: "user-index-gcoins"
      }, {
        path: "home-message",
        component: _122c564b,
        name: "user-index-home-message"
      }, {
        path: "honer",
        component: _2526bdf2,
        name: "user-index-honer"
      }, {
        path: "inbox",
        component: _6ffaf884,
        name: "user-index-inbox"
      }, {
        path: "income-statistics",
        component: _2fa6dff6,
        name: "user-index-income-statistics"
      }, {
        path: "invite",
        component: _0eaf408a,
        name: "user-index-invite"
      }, {
        path: "level",
        component: _3be32000,
        name: "user-index-level"
      }, {
        path: "live",
        component: _152b9fb8,
        name: "user-index-live"
      }, {
        path: "outbox",
        component: _059def33,
        name: "user-index-outbox"
      }, {
        path: "pay-statistics",
        component: _19f6bacc,
        name: "user-index-pay-statistics"
      }, {
        path: "question",
        component: _7e76bc2c,
        name: "user-index-question"
      }, {
        path: "teacher-like",
        component: _fc9bf958,
        name: "user-index-teacher-like"
      }, {
        path: "viewpoint",
        component: _215fcbce,
        name: "user-index-viewpoint"
      }, {
        path: "vip",
        component: _4bb84847,
        name: "user-index-vip"
      }, {
        path: "voucher",
        component: _259162ec,
        name: "user-index-voucher"
      }, {
        path: "article/deliver",
        component: _68f70aa1,
        name: "user-index-article-deliver"
      }, {
        path: "article/order-record",
        component: _f0c96938,
        name: "user-index-article-order-record"
      }, {
        path: "level/record",
        component: _42664393,
        name: "user-index-level-record"
      }, {
        path: "live/as-guest",
        component: _af1926ca,
        name: "user-index-live-as-guest"
      }, {
        path: "question/deblock",
        component: _4f547cc4,
        name: "user-index-question-deblock"
      }, {
        path: "viewpoint/package",
        component: _fe7fc866,
        name: "user-index-viewpoint-package"
      }, {
        path: "viewpoint/single",
        component: _073fd111,
        name: "user-index-viewpoint-single"
      }, {
        path: "vip/orderlist",
        component: _5cd32ce1,
        name: "user-index-vip-orderlist"
      }, {
        path: "inbox/:id?",
        component: _058b44b4,
        name: "user-index-inbox-id"
      }, {
        path: "outbox/:id?",
        component: _db97bb4a,
        name: "user-index-outbox-id"
      }]
    }, {
      path: "/vip",
      component: _6dc572da,
      name: "vip"
    }, {
      path: "/article/list",
      component: _6016647a,
      name: "article-list"
    }, {
      path: "/question/teacher-list",
      component: _6e80d054,
      name: "question-teacher-list"
    }, {
      path: "/room/apply",
      component: _30898d84,
      name: "room-apply"
    }, {
      path: "/vip/edit",
      component: _11efed32,
      name: "vip-edit"
    }, {
      path: "/article/detail/:id?",
      component: _5bd45650,
      name: "article-detail-id"
    }, {
      path: "/article/list/:id",
      component: _0ed164eb,
      name: "article-list-id"
    }, {
      path: "/question/detail/:id?",
      component: _b2d24ca4,
      name: "question-detail-id"
    }, {
      path: "/question/list/:id?",
      component: _2459a741,
      name: "question-list-id"
    }, {
      path: "/room/history-list/:id?",
      component: _2c3575b3,
      name: "room-history-list-id"
    }, {
      path: "/room/history/:id?",
      component: _7060e486,
      name: "room-history-id"
    }, {
      path: "/vip/detail/:id?",
      component: _b0c6a9de,
      name: "vip-detail-id"
    }, {
      path: "/vip/room/:id?",
      component: _660199c7,
      name: "vip-room-id"
    }, {
      path: "/intro/:id?",
      component: _5c4371da,
      name: "intro-id"
    }, {
      path: "/library/:book",
      component: _97c2d160,
      name: "library-book"
    }, {
      path: "/question/:t?",
      component: _16718340,
      name: "question-t"
    }, {
      path: "/room/:id",
      component: _79be4f8a,
      name: "room-id"
    }, {
      path: "/library/:book/:chapter",
      component: _c83d9000,
      name: "library-book-chapter"
    }, {
      path: "/",
      component: _3f1cfbc6,
      name: "index"
    }],

    fallback: false
  })
}
