import unescape from 'lodash/unescape'
import { playAudio } from '@/utils/Misc'

// 老师相关数据
export const state = () => {
  return {
    // 直播室
    data: null,
    isLoading: {
      message: true,
      comment: true
    },
    // 直播室直播消息
    messageList: [],
    // 直播室互动消息
    commentList: [],
    // 直播室付费观点数据
    encryptedViewList: [],
    // 直播室邀请嘉宾
    guests: [],
    // 用于轮询的索引
    mIndex: 0,
    cIndex: 0,
    forbidList: [],
    // 直播室新消息
    newMessageNum: 0,
    // 人气
    online: null,
    // 直播观点
    live: {
      status: false,
      quoteId: 0,
      quoteUserName: null
    },
    // 评论观点
    chat: {
      status: false,
      replyId: 0,
      replyUserName: null
    },
    // 是否开启websocket
    isWebsocket: false,
    // 用户是否在当前页面
    isFocus: true
  }
}

export const getters = {
  // 当前用户是否是该直播室嘉宾
  isGuest (state, getters, rootState) {
    return rootState.user.data ? state.guests.map(_ => _.userId).indexOf(rootState.user.data.id) !== -1 : false
  },
  // 当前用户是否是该直播室老师
  isTeacher (state, getters, rootState) {
    return rootState.user.data && state.data && rootState.user.data.id === state.data.teacherId
  },
  // 获取付费观点信息
  getEncryptedViewData: state => (id) => {
    return state.encryptedViewList.find(_ => _.id === id)
  }
}

export const actions = {
  // 获取直播室基础数据
  initRoomData ({ commit }, id) {
    return this.$axios._get('Room/Detail', {
      id
    }).then(res => {
      commit('setData', res)
      // 设置消息模式：轮训或者websocket
      commit('setWebsocket', !!res.socket)
      return Promise.resolve(res)
    }).catch(error => {
      return Promise.reject(error)
    })
  },

  // 初始化直播室消息数据
  initList ({ commit, getters }, topicId) {
    const funs = ['message', 'comment'].map(type => {
      return this.$axios._get('Room/TopicMessage', {
        id: topicId,
        type,
        num: 50
      })
    })
    return Promise.all(funs).then(function (data) {
      let messages = data[0]
      let comments = data[1]
      // if (!getters.isTeacher) {
      //   messages = messages.filter(_ => {
      //     if (_.m === 'ADD' && _.data.type === 2) {
      //       if (_.data.status) {
      //         return true
      //       } else {
      //         return false
      //       }
      //     } else {
      //       return true
      //     }
      //   })
      // }
      commit('setMessageList', messages)
      if (messages.length > 0 && messages[messages.length - 1].index >= 0) {
        commit('setMessageIndex', messages[messages.length - 1].index)
      }
      commit('setCommentList', comments)
      if (comments.length > 0 && comments[comments.length - 1].index >= 0) {
        commit('setCommentIndex', comments[comments.length - 1].index)
      }
    })
  },

  // 向上N条
  upList ({ commit, state, getters }, { topicId, type, num }) {
    return this.$axios._get('Room/TopicMessageUp', {
      id: topicId,
      index: state[type + 'List'][0].index - 1,
      type,
      num
    }).then(res => {
      if (!res.length || res.length < 50) {
        commit('setLoadingStatus', { flag: false, type })
      }
      if (res.length) {
        if (type === 'message' && !getters.isTeacher) {
          res = res.filter(_ => {
            if (_.m === 'ADD' && _.data.type === 2) {
              if (_.data.status) {
                return true
              } else {
                return false
              }
            } else {
              return true
            }
          })
        }

        commit('set' + type.substr(0, 1).toUpperCase() + type.substr(1) + 'List', res.concat(state[type + 'List']))
      }
    })
  },

  // 轮询获取直播室消息
  async polling ({ commit, dispatch, state, getters }, topicId) {
    const params = {
      topicId,
      mIndex: state.mIndex,
      cIndex: state.cIndex
    }
    let resp
    try {
      resp = await this.$axios.getJsonp('https://roomapi.yifucj.com/room', params, 'jsoncallback')
    } catch (err) {
      console.log(err)
    }
    if (resp) {
      // 获取到message消息
      if (resp.message.length) {
        // console.log(resp)
        for (let item of resp.message) {
          // 去重
          if (state.messageList.every(_ => _.index !== item.index)) {
            if (item.data.content && item.data.content.match(/&quot;/g)) {
              // 表情和图片的src链接不能用双引号，否则不能通过JSON.parse方法
              item.data.content = item.data.content.replace(/&quot;/g, '')
            }
            // lua获取数据是html转义过的，需要翻转
            item = JSON.parse(unescape(JSON.stringify(item)))
            // 如果有付费观点数据，重新获取付费观点数据
            if (item.data.type === 10) {
              let res = await this.$axios._get('/EncryptedView/Detail', { id: item.data.relationId })
              res.type = 10
              res.relationId = item.data.relationId
              commit('addEncryptedViewList', res)
              item.data = res
            }
            // 更新单条消息
            commit('addMessage', item)
            commit('setMessageIndex', item.index)
            !getters.isTeacher && playAudio('yf_sound_' + state.data.id)
          }
        }
      }

      // 获取到comment消息
      if (resp.comment.length) {
        for (let item of resp.comment) {
          // 去重
          if (state.commentList.every(_ => _.index !== item.index)) {
            commit('addComment', item)
            commit('setCommentIndex', item.index)
            getters.isTeacher && playAudio('yf_sound_' + state.data.id)
          }
        }
      }
    }
  },

  // 初始化历史直播室消息数据
  initHistoryList ({ commit, state, getters }, { id, type, page, num }) {
    let params = {
      id,
      type: type ? 'comment' : 'message',
      page,
      num
    }
    return this.$axios._get('/History/TopicMessage', params).then(res => {
      if (params.type === 'message') {
        res.list = res.list.filter(_ => {
          if (_.type === 2) {
            if (_.status) {
              return true
            } else {
              return false
            }
          } else {
            return true
          }
        })
      }
      commit(type ? 'setCommentList' : 'setMessageList', state[(type ? 'comment' : 'message') + 'List'].concat(res.list))
      return res
    })
  },

  // 初始化直播室付费观点数据
  initEncryptedViewList ({ commit }, topicId) {
    return this.$axios._get('EncryptedView/List', { topic_id: topicId, num: 999 })
      .then(resp => {
        commit('setEncryptedViewList', resp.list)
      })
  },

  // 初始化直播室邀请嘉宾数据
  initGuests ({ commit }, { topicId, roomId }) {
    return this.$axios._get('/RoomGuest/List', { topic_id: topicId, roomId })
      .then(res => {
        commit('setGuests', res)
      })
  }
}

export const mutations = {
  setData (state, data) {
    state.data = data
  },
  setWebsocket (state, status) {
    state.isWebsocket = status
  },
  setLoadingStatus (state, { flag, type }) {
    state.isLoading[type] = flag
  },
  setMessageList (state, data) {
    state.messageList = data
  },
  setCommentList (state, data) {
    state.commentList = data
  },
  setEncryptedViewList (state, data) {
    state.encryptedViewList = data
  },
  addEncryptedViewList (state, data) {
    state.encryptedViewList.push(data)
  },
  // 添加一条直播数据
  addMessage (state, data) {
    if (state.isWebsocket && state.messageList.every(_ => _.data.id !== data.data.id)) {
      state.messageList.push(data)
      state.mIndex = data.index + 1
    } else if (state.messageList.every(_ => _.index !== data.index)) {
      state.messageList.push(data)
      state.mIndex = data.index + 1
    }
  },
  // 添加一条互动数据
  addComment (state, data) {
    if (state.isWebsocket && state.commentList.every(_ => _.Index !== data.Index)) {
      state.commentList.push(data)
      state.cIndex = data.index + 1
    } else if (state.commentList.every(_ => _.Index !== data.Index)) {
      state.commentList.push(data)
      state.cIndex = data.index + 1
    }
  },
  removeComment (state, data) {
    state.commentList.push(data)
    state.cIndex = data.index + 1
  },
  // 添加一条付费观点数据
  addEncryptedView (state, data) {
    state.encryptedViewList.push(data)
  },
  setOnline (state, data) {
    state.online = data
  },
  setMessageIndex (state, i) {
    state.mIndex = i + 1
  },
  setCommentIndex (state, i) {
    state.cIndex = i + 1
  },
  // 设置引用
  setQuote (state, { id, username }) {
    state.live.status = !state.live.status
    state.live.quoteId = id
    state.live.quoteUserName = username
  },
  // 取消引用
  cancelQuote (state) {
    state.live.quoteId = 0
    state.live.quoteUserName = null
  },
  // 设置回复
  setReply (state, { id, username }) {
    state.chat.status = !state.chat.status
    state.chat.replyId = id
    state.chat.replyUserName = username
  },
  // 取消回复
  cancelReply (state) {
    state.chat.replyId = 0
    state.chat.replyUserName = null
  },
  setNewMessageNum (state, num = 0) {
    if (!num) {
      state.newMessageNum = 0
    } else {
      state.newMessageNum += num
    }
  },
  // 浏览器是否在浏览当前直播室页面
  setPageFocus (state) {
    state.isFocus = !state.isFocus
    if (state.isFocus) {
      state.newMessageNum = 0
    }
  },
  // 设置今日主题名称
  setTopicName (state, data) {
    state.data.lastTopicName = data
  },
  // 设置邀请嘉宾
  setGuests (state, data) {
    state.guests = data
  },
  // 添加邀请嘉宾
  addGuest (state, data) {
    state.guests = state.guests.concat(data)
  },
  // 删除邀请嘉宾
  removeGuest (state, id) {
    let i = state.guests.map(_ => _.userId || _.id).indexOf(id)
    i !== -1 && state.guests.splice(i, 1)
  },
  clean (state) {
    state.data = null
    state.messageList = []
    state.commentList = []
  }
}
