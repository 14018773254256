import { render, staticRenderFns } from "./Ask.vue?vue&type=template&id=a4707548&scoped=true&"
import script from "./Ask.vue?vue&type=script&lang=js&"
export * from "./Ask.vue?vue&type=script&lang=js&"
import style0 from "./Ask.vue?vue&type=style&index=0&id=a4707548&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a4707548",
  null
  
)

component.options.__file = "Ask.vue"
export default component.exports