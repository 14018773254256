<template>
  <div class="layouts-create">
    <nuxt />
  </div>
</template>

<script>
export default {
  name: 'layouts-create'
}
</script>

<style lang="less" scoped>
.layouts-create {
  background-color: #e9c385; background-image: url('~assets/layouts-create-bg.jpg');
}
</style>
