<template>
  <el-pagination v-if="total > num"
    @size-change="changeSize"
    @current-change="changePage"
    :current-page="currentPage"
    :page-sizes="[10, 20, 30, 40]"
    :page-size="num"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total">
  </el-pagination>
</template>

<script>
export default {
  name: 'component-pager',
  props: {
    // 默认pageSize /question/teacher-list专用
    num: {
      type: Number,
      default: 10
    },
    // 总条数
    total: {
      type: Number,
      required: true
    }
  },
  methods: {
    changeSize (num) {
      // 改变每页显示数量
      let query = {}
      // 这样的处理是为了兼容有查询参数的页面，比如用户中心页面
      for (let name in this.$route.query) {
        query[name] = this.$route.query[name]
      }
      query.num = num
      this.$router.push({ query })
    },
    changePage (page) {
      // 改变页数
      let query = {}
      // 这样的处理是为了兼容有查询参数的页面，比如用户中心页面
      for (let name in this.$route.query) {
        query[name] = this.$route.query[name]
      }
      query.page = page
      // 初次点击分页时没有num，在这里加上
      if (!query.num) query.num = this.num
      this.$router.push({ query })
    }
  },
  computed: {
    currentPage () {
      return +this.$route.query.page || 1
    }
  }
}
</script>

<style lang="less">
.el-pagination {
  text-align: center; padding: 30px 0;
}
</style>

