export const state = () => ({
  data: {},
  // 红包活动金币
  redpackGcoins: 0,
  // 关注的老师数据
  likes: [],
  // 常用观点
  normalView: [],
  // 问答数据
  ask: {
    // 等待回答数量，老师才有
    wait: 0,
    // 免费问次数
    free: 0
  },
  // 用户是否在直播间
  isInLiveRoom: false
})

export const getters = {
  // 关注的老师ids, array
  likeTeacherIds: state => {
    let ids = []
    for (let item of state.likes) {
      if (item.type === 1) {
        ids.push(item.id)
      }
    }
    return ids
  },
  // 当前用户是否是老师
  isTeacher: state => state.data && state.data.type === 1,
  // isVipRoomManger: state => state.data && state.data.type === 9,
  // isVipRoomTeacher: (state, getters, rootState) => {
  //   console.log('rootState: ' + rootState)
  // 当前用户是否是管理员
  isManger: state => state.data && state.data.type === 9,
  // 当前用户是否是测试号
  isTest: state => state.data && state.data.type === 7,
  // 当前用户是否是小号
  isGhost: state => state.data && state.data.type === 8
}

export const actions = {
  // 获取用户基本数据
  async getInfo ({ commit }) {
    try {
      const data = await this.$axios._get('/User/Info')
      commit('setData', data)
      return data
    } catch (e) {
      console.log('获取用户基本数据失败[/User/Info]')
      return Promise.reject(e)
    }
  },

  // 获取用户关注的老师数据
  async getLike ({ commit }) {
    try {
      const likes = await this.$axios._get('/User/LikeList', { page: 1, num: 999, type: 1 })
      if (likes && likes.list.length > 0) {
        commit('setLikes', likes.list)
      }
    } catch (e) {
      console.log('获取用户关注的老师数据失败[/User/LikeList]')
      return Promise.reject(e)
    }
  },

  // 关注/取消关注
  async like ({ commit }, { teacher, isLike }) {
    try {
      let resp = await this.$axios._post('/User/Like', { user_id: teacher.id, type: isLike ? 1 : 0 })
      commit('like', { teacher, flag: isLike })
      return resp
    } catch (e) {
      console.log('关注/取消关注失败[/User/Like]')
      return Promise.reject(e)
    }
  },

  // 获取问答数据
  async initAskNum ({ commit }) {
    try {
      let resp = await this.$axios._get('/Ask/Num')
      commit('setAskWait', resp.wait)
      commit('setAskFree', resp.free)
    } catch (e) {
      console.log('获取问答数据失败[/Ask/Num]')
      return Promise.reject(e)
    }
  },

  // 获取常用观点数据
  async initNormalView ({ commit }) {
    try {
      let res = await this.$axios._get('/NormalView/List')
      commit('setNormalView', res.list)
    } catch (e) {
      console.log('获取常用观点数据失败[/NormalView/List]')
      return Promise.reject(e)
    }
  },

  // 获取红包活动金币余额
  async getRedpackGcoins ({ commit }) {
    let res = await this.$axios._get('/GoldCoinAccount/Info')
    commit('setRedpackGcoins', res.goldCoins)
  }
}

export const mutations = {
  setData (state, data) {
    state.data = data
  },
  setLikes (state, data) {
    state.likes = data
  },
  like (state, { teacher, flag }) {
    let i = state.likes.map(_ => _.id).indexOf(teacher.id)
    if (!flag && i === -1) {
      state.likes = state.likes.concat({ id: teacher.id, username: teacher.userName, type: 1 })
      return true
    } else if (flag && i !== -1) {
      state.likes.splice(i, 1)
      return true
    } else {
      return false
    }
  },
  setUserPosition (state, flag) {
    state.isInLiveRoom = flag
  },
  setAskWait (state, data) {
    state.ask.wait = data
  },
  setAskFree (state, data) {
    state.ask.free = data
  },
  setGcoins (state, data) {
    state.data.gcoins = data
  },
  setGcents (state, data) {
    state.data.gcents = data
  },
  setRedpackGcoins (state, data) {
    state.redpackGcoins = data
  },
  setFcoins (state, data) {
    state.data.fcoins += data
  },
  // 设置常用观点
  setNormalView (state, data) {
    state.normalView = data
  },
  // 添加常用观点
  addNormalView (state, data) {
    state.normalView = state.normalView.concat(data)
  },
  // 更新常用观点
  updateNormalView (state, data) {
    let i = state.normalView.map(_ => _.id).indexOf(data.id)
    state.normalView.splice(i, 1, data)
  },
  // 删除常用观点
  removeNormalView (state, id) {
    let i = state.normalView.map(_ => _.id).indexOf(id)
    state.normalView.splice(i, 1)
  },
  // 设置站内信回复id和name
  setMsgReply (state, data) {
    state.reply = {
      id: data.id,
      userName: data.userName
    }
  },
  clean (state) {
    state.data = null
    state.likes = []
    state.normalView = []
  }
}
