<template>
<div class="yf-nav-wrap">
  <div class="container">
    <div class="container-content clearfix main-1">
      <ul class="user-info left">
        <template class="user-info-item left" v-if="!this.authUser">
          <nuxt-link tag="li" class="item left" to="/login">登录</nuxt-link>
          <nuxt-link tag="li" class="item left" to="/register">注册</nuxt-link>
        </template>
        <template v-else>
          <li class="item user left" style="padding-left: 0;">
            欢迎您，<nuxt-link to="/user" target="_blank" class="yf-text_blue">{{ authUser.userName }}</nuxt-link>
            <span class="loginout" @click="logout">[退出]</span>
          </li>
          <nuxt-link tag="li" to="/user/level" style="cursor: pointer;" class="item left default" v-if="!$store.getters['user/isTeacher']">等级：<i class="sprite-level" :class="[`level-${authUser.normalLevel}`]"></i>{{ authUser.normalLevel | level }}</nuxt-link>
          <li class="item left default">G币: {{ authUser.gcoins }}</li>
          <li class="item left default">积分: {{ authUser.gcents }}</li>
          <nuxt-link to="/user/voucher" class="item left">代金券: {{ authUser.voucherCount }}</nuxt-link>
          <nuxt-link tag="li" to="/user/inbox" class="item left">
            站内信<span class="bedge inline" v-if="messageNum > 0">{{ messageNum > 99 ? '99' : messageNum }}</span>
          </nuxt-link>
        </template>
        <li @click="toPay" class="item left pay">G币充值</li>
      </ul>
      <div class="web-info right">
        <nuxt-link class="item" to="/" target="_blank" rel="noopener">网站首页</nuxt-link>
        <nuxt-link class="item" to="/about?type=2" target="_blank" rel="noopener">使用帮助</nuxt-link>
        <div class="item wx-code" style="padding-right: 0;">手机看直播
          <div class="wx-code-img">
            <img width="120" height="120" src="~assets/yifucj.png" alt="安卓APP" />
            <span>安卓手机浏览器扫一扫</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
import { mapState } from 'vuex'
import Dialog from '@/utils/Dialog'
import { level } from '@/utils/Filters'

export default {
  name: 'component-top',
  mounted () {
    // 初始化站内信数据
    if (this.authUser) {
      this.$store.dispatch('getMessageNum')
    }
  },
  methods: {
    login () {
      Dialog.login()
    },
    logout () {
      // 删除token
      let arr = ['yf_jwt', 'yf_guid', 'yf_remember']
      arr.forEach(_ => {
        this.$cookies.remove(_, { domain: process.env.DOMAIN })
      })
      window.location.reload()
    },
    toPay () {
      if (!this.authUser) {
        Dialog.alert('需要登录网站后才能进行充值')
      } else {
        this.$router.push('/pay')
      }
    }
  },
  filters: {
    level
  },
  computed: {
    ...mapState({
      authUser: state => state.user.data,
      messageNum: state => state.messageNum
    })
  }
}
</script>

<style lang="less" scoped>
.yf-nav-wrap {
  position: fixed; width: 100%; position: sticky; top: 0; z-index: 999;
  .container {
    background-color: #fafafa;
    .container-content {
      position: relative;
      .user-info,
      .web-info {
        font-size: 12px; color: #666666;
        .item {
          line-height: 20px; padding: 10px 9px; cursor: pointer;
        }
      }
      .user-info {
        .item {
          &:not(:last-child) { border-right: 1px solid #e6e6e6; }
          .bedge {
            margin-left: 5px; background-color: #ff0107; color: #ffffff; padding: 0 5px; border-radius: 10px; line-height: 18px; font-weight: bold;
          }
          &.default {
            cursor: default; padding-top: 0; padding-bottom: 0; line-height: 40px;
            .sprite-level {
              vertical-align: -15%; transform: scale(.7);
            }
          }
          &.pay { color: #ff0107; }
          &.user:hover {
            color: #666666;
          }
          .loginout:hover, &:hover { color: #ff8134; }
          .loginout { margin-left: 5px; }
        }
      }
      .web-info {
        .item {
          display: inline-block;
          &:hover { color: #ff0107; }
          &.wx-code {
            position: relative; cursor: default;
            .wx-code-img {
              display: none; position: absolute; right: 0; top: 40px; z-index: 900; background-color: #ffffff; padding: 10px; box-shadow: 0 2px 3px #999;
            }
            &:hover {
              .wx-code-img {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
