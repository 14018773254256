import Vue from 'vue'
import Cookie from 'cookie'
import JSCookie from 'js-cookie'

// Called only on client-side
export const getCookies = (str) => {
  return Cookie.parse(str || '')
}

/*
** Executed by ~/.nuxt/index.js with context given
** This method can be asynchronous
*/
export default ({ req, res }, inject) => {
  // Inject `cookies` key
  // -> app.$cookies
  // -> this.$cookies in vue components
  // -> this.$cookies in store actions/mutations
  inject('cookies', new Vue({
    data: () => ({
      cookies: getCookies(process.server ? req.headers.cookie : document.cookie)
    }),
    methods: {
      get (name) {
        return this.cookies[name]
      },
      set (name, value, expires) {
        let isServer = process.server
        if (isServer) {
          if (typeof name === 'string') {
            throw new Error('服务端渲染时参数必须为数组')
          }
          let arr = name.map(_ => {
            return Cookie.serialize(_.name, String(_.value), {
              domain: process.env.DOMAIN,
              expires: _.expires ? new Date(Date.now() + 1000 * 60 * 60 * 24 * _.expires) : undefined,
              path: '/'
            })
          })
          res.setHeader('Set-Cookie', arr)
        } else {
          JSCookie.set(name, value, { domain: process.env.DOMAIN, expires, path: '/' })
        }
        this.cookies = getCookies(isServer ? req.headers.cookie : document.cookie)
      },
      remove (...args) {
        JSCookie.remove(...args)
        this.cookies = getCookies(document.cookie)
      }
    }
  }))
}
