<template>
  <!-- 解锁问答弹出框 -->
  <el-dialog :visible.sync="visible">
    <div class="unlockDialog" v-if="data">
      <div class="title_wrap">解锁问答</div>
      <div class="text_wrap">
        <p class="title">{{ data.userName }}支付了 <i>{{ data.gcoins }}</i> G币获得此问题的答案！您只需要支付 <i>{{ price }}</i> G币即可查看答案！</p>
       </div>
      <div class="owner_wrap">
        <p class="left">本次解锁花费 <i>{{ price }}</i> G币</p>
        <p class="right">您的G币数：{{ authUser.gcoins }}<nuxt-link to="/pay" target="_blank" rel="noopener">充值 >></nuxt-link></p>
      </div>
      <div class="btn_wrap">
        <p v-if="authUser.gcoins - price < 0" class="warning"><span><i class="iconfont icon-warning"></i></span>您的G币余额不足</p>
        <button type="button" :class="{ warningshow: authUser.gcoins - price > 0 }" @click="handlePay">确认解锁</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: 'unlock-dialog',
  data () {
    return {
      visible: false,
      data: null
    }
  },
  computed: {
    authUser () {
      return this.$store.state.user.data
    },
    price () {
      return Math.ceil(this.data.gcoins / 2)
    }
  },
  methods: {
    initData (data) {
      this.data = data
    },
    handlePay (e) {
      if(!this.data && (this.authUser.gcoins - Math.ceil(this.data.gcoins / 2) < 0)) {
        console.warn('数据出错！')
        return
      }
      e.target.disabled = true
      this.$axios._post('Ask/Buy', { id: this.data.id }).then(res => {
        e.target.disabled = false
        this.visible = false
        this.$emit('update', res)
      }).catch(err => {
        e.target.disabled = false
      })
    }
  }
}
</script>

<style lang="less" scoped>
.unlockDialog { padding: 0 28px; padding-top: 15px;
  .title_wrap { font-size: 20px; color: #999999;  padding-bottom: 24px; }
  .text_wrap {
    .title { font-size: 14px; padding-bottom: 28px; border-bottom:2px solid #e6e6e6;
      i { color: #e53b3e; font-size: 14px; }
    }
  }
  .owner_wrap { padding-top: 40px; padding-bottom: 8px; overflow: hidden;
    p { font-size: 14px;
      i { color: #ff0133; font-size: 14px; }
      a { color:#ff0133; margin-left: 10px; }
    }
  }
  .btn_wrap {  text-align: center;
    .warning { text-align: left; background: #fbfba5; height: 30px; line-height: 30px; color: #e53b3c; font-size: 14px;
      span { margin:0 8px;
        i { font-size: 18px; }
      }
    }
    button { border: none; background: #CCCCCC; width: 300px; height: 50px; font-size: 16px; color: #ffffff; margin-top:14px; cursor: pointer; }
    .warningshow { margin-top:44px; background: #e43a3d; }
  }
}
</style>
