<template>
	<el-dialog class="ask-box" :visible.sync="visible" :before-close="handleReset" width="760px">
    <div class="content-wrap">
      <template v-if="type === 2">
        <h3>选择老师：</h3>
        <ul>
          <li @click="setData(item)" v-for="(item, key) in list" class="teacher_item" :key="key" :class="{ active_item: teacherItem.id === item.id }">
            <p v-if="teacherItem.id === item.id" class="dagou"></p>
            <p class="img_wrap left"><img :src="isSupportWebp(item.avatar)" :alt="item.userName" /></p>
            <div class="text_wrap left">
              <p>{{ item.userName }}</p>
              <p><span>{{ item.questionPrice }}</span>G币/次</p>
            </div>
          </li>
          <div class="teacher_item link_item"><nuxt-link target="_blank" rel="noopener" :to="`/question/teacher-list${isPay ? '' : '?order=1'}`">更多老师...</nuxt-link></div>
        </ul>
      </template>
      <div class="content_text_wrap">
        <p class="ispay_p">
          <template v-if="type === 1 && teacherItem">向<em class="red">{{ teacherItem.name }}</em>提问：</template>
          <template v-else><em class="gray">提出你的问题</em></template>
          <i class="tishi yf-text_12 right">
            <template v-if="isPay">若老师在次日24点前未回答，G币将退回</template>
            <template v-else>您今日还剩<em class="red">{{ freeNum }}</em>次免费提问</template>
          </i>
        </p>
        <div class="text_area">
          <textarea maxlength="120" v-model="content" ref="input" placeholder="请输入提问问题"></textarea>
          <p class="worldnum">还剩余<span>{{ 120 - content.length }}</span>字</p>
        </div>
      </div>
      <!--免费问提示-->
      <div class="pay_Gbi_show" v-if="!isPay">
        <p class="tishi">若老师在次日24点前未回答，该提问过期</p>
      </div>
      <!--付费提问的个人G币显示-->
      <div class="pay_Gbi_show" v-else-if="teacherItem">
        <div>
          <p class="left">本次提问需花费<span>{{ teacherItem.price }}</span>G币</p>
          <p class="right">您的G币数：{{ authUser.gcoins }} <nuxt-link to="/pay" target="_blank" rel="noopener">充值 >></nuxt-link></p>
        </div>
        <p v-if="authUser.gcoins && authUser.gcoins - teacherItem.price < 0" class="warning"><i class="iconfont icon-th"></i>您的G币余额不足</p>
      </div>
      <div class="btn_wrap_pay">
        <button type="button" :class="{ yf_warning: isWarning }" @click="handleSubmit">提交问题</button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from 'vuex'
import Dialog from '@/utils/Dialog'
import Webp from '@/mixins/Webp'

export default {
  mixins: [Webp],
  name: 'dialog-ask',
  data () {
    return {
			type: 1,					// 弹窗类型<1. 向单个老师提问 2. 展示提问老师列表>
      list: [],       // 渲染列表
      visible: false,   // 此页面是否显示
      isPay: null,      // 问答是否付费
      content: '',      // 内容
      teacherItem: null // 选中的老师
    }
  },
  methods: {
    async initData (option) {
      this.list = []
			if (option instanceof Object) {
        // 向某个老师提问
				this.type = 1
				// 判断是免费问还是付费问
        this.isPay = option.questionPrice
				// 判断是否免费问次数
				if (!this.isPay && this.freeNum <= 0) {
					this.visible = false
					Dialog.alert('您的免费次数已用完，请付费提问或明天继续~')
					return
				}
				this.setData(option)
			} else {
        // 显示提问老师列表
				this.type = 2
        this.isPay = option
        if (this.isPay) {
          let res = await this.$axios._get('/Ask/TeacherList', {
            page: 1,
            num: 5,
            order: 'num'
          })
          this.list = res.list.filter(_ => _.questionPrice > 0 && _.id !== 54)
        } else {
          if (this.freeNum <= 0) {
  					this.visible = false
  					Dialog.alert('您的免费次数已用完，请付费提问或明天继续~')
  					return
          }
          if (this.$store.state.adList['freePaperTeacher']) {
            this.list = this.$store.state.adList['freePaperTeacher'].map(item => item.relationData)
          }
        }
        this.setData(this.list[0])
      }
    },
    setData (item) {
      this.teacherItem = {
				id: item.id,
				name: item.userName,
				price: item.questionPrice,
      }
    },
    handleSubmit (e) {
      if (this.isWarning) return

      let params = {
        teacher: this.teacherItem.id,
        content: this.content,
        gcoins: this.teacherItem.price
      }
      e.target.disabled = true
      this.$axios._post('Ask/Add', params).then(res => {
        // 如果是免费问，次数-1
        if (params.gcoins === 0) {
          this.$store.commit('user/setAskFree', this.freeNum - 1)
        }
        e.target.disabled = this.visible = false
				this.handleReset()
				Dialog.alert('您的提问已推送给老师，请耐心等待~~', {
          type: 'success',
          showCancelButton: true,
          cancelButtonText: '查看我的提问',
          confirmButtonText: '关闭继续浏览'
        }).catch(err => {
          this.$router.push('/user/question')
        }).finally(() => {
          this.$store.commit('user/setGcoins', this.authUser.gcoins - params.gcoins)
        })
      }).catch(err => {
        e.target.disabled = false
      })
    },
		handleReset (done) {
			this.type = 1
			this.content = ''
			this.list = this.isPay = this.teacherItem = null
			done && done()
		},
  },
  computed: {
    ...mapState('user', {
      freeNum: state => state.ask.free,
      authUser: state => state.data
    }),
    isWarning () {
			return !this.content || (this.authUser.gcoins - this.teacherItem.price < 0)
    }
	}
}
</script>

<style lang="less" scoped>
.ask-box {
  .content-wrap { width: 560px; margin: 0 auto;
    h3 { font-size: 20px; line-height: 36px; color: #999999;}
    ul { overflow: hidden;
      .teacher_item { width: 176px; height: 70px; float: left; border: 1px solid #dddddd; margin-right:10px; margin-bottom: 6px; position: relative; cursor: pointer;
        .dagou { position: absolute; width: 22px; height: 22px; background: url(~assets/dagou.png) no-repeat; bottom: 0; right: 0; }
        .img_wrap { width: 70px; padding-left:10px; padding-top: 8px;
          img { width: 50px; height: 50px; border-radius: 50%;}
        }
        .text_wrap { padding-top: 6px;
          p { line-height: 27px; font-size: 14px;
            span { color: #e43a3b; font-size: 18px; margin-right: 4px;}
          }
        }
      }
      .active_item { border: 1px solid #FE9D0E;}
      .link_item {
        text-align: center; line-height: 70px; border: none;
        a { color: #e43c3c; font-size: 14px; }
      }
    }
    .content_text_wrap {
       > p  { font-size: 20px; color: #999999; padding-top: 26px; padding-bottom: 12px;
        span { margin-left: 275px; color: #323232; font-size: 14px;
          i { color: #3366ff; font-size: 14px; padding: 0 2px;}
         }
      }
      .ispay_p { padding: 4px 0 8px; color: #333;
        .gray { color: #999999; }
        .red { color: #e33a3d; }
        .tishi { padding-top: 5px; }
      }
      .text_area { width: 560px; border: 1px solid #5C5C5C;
        textarea { width: 100%; height: 122px; font-size: 14px; padding: 10px; resize: none; border: none; outline: none; }
        .worldnum { text-align: right; padding: 0 10px 5px 0;
          span { color: #0099FF; }
        }
      }
      .ispay_text_area { height: 100px;
        textarea { height: 76px;}
      }
    }
    .pay_Gbi_show { padding-right: 10px;
      div { overflow: hidden;
        p { color: #323232;
          span { color: #e43a3d;}
          a { color: #e43a3d; }
        }
      }
      .warning {color: #e6393d; background: #fbfba5; height: 30px; line-height: 30px; clear: both; padding-left: 10px;
        i { font-size: 18px; margin-right: 6px; color: #e43a3d; display: inline-block; width: 20px; height: 20px; }
      }
      .tishi { font-size: 12px; }
    }
    .btn_wrap_pay {width: 550px; text-align: center; margin-top: 20px;
      button { width: 300px; height: 50px; outline: none; background: #e43a3d; border: none; font-size: 16px; color: #FFFFFF; cursor: pointer; outline: none;}
    }
  }
}
</style>
