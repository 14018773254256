import unescape from 'lodash/unescape'

import { playAudio } from '@/utils/Misc'

const state = () => {
  return {
    isLoading: false,
    message: null,
    data: null, // vip 直播室数据
    list: [], // vip直播室消息元数据
    forbidList: [], // 禁言用户列表
    serviceDay: [], // 用户服务时间数据，老师不判断
    newMessageNum: 0, // 判断轮询是否获取到新的消息体
    upDate: null, // 有历史消息的最近一天
    index: 0, // 轮询lastIndex
    isWebsocket: false,
    isFocus: true
  }
}

const getters = {
  isVipRoomTeacher: (state, getters, rootState) => {
    return state.data && rootState.user.data && state.data.teacherId === rootState.user.data.id
  },
  isVipRoomManger: (state) => {
    return state.data && (state.data.userType === 1 || state.data.userType === 3)
  }
}

const actions = {
  initVipRoomData ({ commit }, params) {
    commit('getVipRoomDataIng')
    return this.$axios._get('/Vip/Detail', params).then(res => {
      commit('getVipRoomDataSuccess', res)
      // 设置消息模式：轮训或者websocket
      commit('setWebsocket', !!res.socket)
      return res
    }).catch(err => {
      commit('getVipRoomDataFail', err)
      return err
    })
  },
  initVipServiceDate ({ commit }, id) {
    return this.$axios._get('/VipRoom/Service', { id }).then(res => {
      commit('setVipRoomServiceDate', res)
      return res
    }).catch(err => {
      console.log(err)
      return err
    })
  },

  // 获取vip message，支持init获取和向上一天获取
  getVipMessage ({ commit, state, dispatch }, { params, isPast }) {
    commit('getVipRoomMessageIng')
    return this.$axios._get('/VipRoom/Message', params).then(res => {
      // 组装时间分割线
      let d
      if (isPast) {
        d = [{ content: params.day, type: 0 }]
      } else {
        d = [{ m: 'ADD', data: { content: params.day, type: 0 } }]
      }
      let messages = d.concat(res.list)
      commit('getVipRoomMessageSuccess', messages)
      // 设置上一天日期
      commit('setVipRoomPrevDate', res.upDate)
      // 设置lastIndex
      if (messages.length > 1) {
        commit('setIndex', messages[messages.length - 1].index)
      }
      return res
    }).catch(err => {
      console.log(err)
      commit('getVipRoomMessageFail', err)
      return err
    })
  },

  // 轮询vip直播室的接口的方法
  async updateVipRoomData ({ commit, state, rootState }, params) {
    let resp = await this.$axios.getJsonp('https://roomapi.yifucj.com/vip', params, 'jsoncallback')
    if (!resp) return
    if (resp.status !== 0) return Promise.reject(resp.status)
    // 获取到消息
    if (resp.message.length) {
      for (let item of resp.message) {
        // 去重
        let index = state.list.map(_ => _.index).indexOf(item.index)
        if (index === -1) {
          let lastIndex
          let num = 0
          let isTeacher = rootState.user.data.id === state.data.teacherId
          // lua获取数据是html转义过的，需要翻转
          item = JSON.parse(unescape(JSON.stringify(item)))
          // 添加一条数据
          commit('addMessage', item)
          lastIndex = item.index
          if (item.m === 'ADD') playAudio('vip_' + state.data.id)
          // 新消息计数器
          if (isTeacher && item.data.type === 6) {
            num++
          } else if (!isTeacher && (item.data.type === 1 || item.data.type === 3)) {
            num++
          }
          if (lastIndex > -1) commit('setIndex', lastIndex)
          if (num > 0) commit('setNewMessageNum', state.newMessageNum + num)
        }
      }
    }
  },
  // 获取禁言列表
  getNoSpeechList ({ commit }, id) {
    this.$axios._get('/VipRoom/ForbidList', { id }).then(res => {
      commit('setVipRoomForbidList', res)
    }).catch(err => {
      console.log(err)
    })
  }
}

const mutations = {
  // 初始化vip直播室
  getVipRoomDataIng (state) {
    state.isLoading = true
    state.message = ''
    state.data = null
    state.list = []
    state.index = 0
  },
  getVipRoomDataSuccess (state, data) {
    state.isLoading = false
    state.data = data
  },
  getVipRoomDataFail (state, err) {
    state.isLoading = false
    state.message = err
  },
  setVipRoomServiceDate (state, data) {
    state.serviceDay = data
  },
  // 初始化直播室消息
  getVipRoomMessageIng (state) {
    state.isLoading = true
    state.message = ''
  },
  getVipRoomMessageSuccess (state, data) {
    state.isLoading = false
    state.list = data
  },
  getVipRoomMessageFail (state, err) {
    state.isLoading = false
    state.message = err
  },
  // 设置最近一次历史直播日期
  setVipRoomPrevDate (state, date) {
    state.upDate = date
  },
  // 设置是否开启互动
  setVipRoomChatStatus (state, status) {
    state.data.isChat = status
  },
  // 设置lastIndex
  setIndex (state, data) {
    state.index = data + 1
  },

  // 添加一条消息数据
  addMessage (state, data) {
    if (state.isWebsocket && state.list.every(_ => _.data.id !== data.data.id)) {
      state.list.push(data)
    } else if (state.list.every(_ => _.index !== data.index)) {
      state.list.push(data)
    }
  },
  // vip直播右侧的消息的删除操作
  removeVipRoomComment (state, data) {
    state.list.push(data)
  },

  setNewMessageNum (state, num = 0) {
    if (!num) {
      state.newMessageNum = 0
    } else {
      state.newMessageNum += num
    }
  },
  // 浏览器是否在浏览当前直播室页面
  setPageFocus (state) {
    state.isFocus = !state.isFocus
    if (state.isFocus) {
      state.newMessageNum = 0
    }
  },

  setVipRoomForbidList (state, data) {
    state.forbidList = data
  },

  setWebsocket (state, status) {
    state.isWebsocket = status
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
